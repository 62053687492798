export enum ReduxActionTypes {
  EDIT_HAT_CLAIM = 'EDIT_HAT_CLAIM',
  EDIT_HAT_PASSWORD = 'EDIT_HAT_PASSWORD',
  EDIT_CURRENT_STEP = 'EDIT_CURRENT_STEP',
  EDIT_HAT_CLAIM_ERROR_MSG = 'EDIT_HAT_CLAIM_ERROR_MSG',
}

export enum ReduxTestingActionTypes {
  EDIT_HAT_CLAIM_TEST = 'EDIT_HAT_CLAIM_TEST',
  EDIT_HAT_PASSWORD_TEST = 'EDIT_HAT_PASSWORD_TEST',
  EDIT_CURRENT_STEP_TEST = 'EDIT_CURRENT_STEP_TEST',
  EDIT_HAT_CLAIM_ERROR_MSG_TEST = 'EDIT_HAT_CLAIM_ERROR_MSG_TEST',
}

export interface ReduxBaseAction {
  type: ReduxActionTypes | ReduxTestingActionTypes;
}
